import { makeStyles } from '@material-ui/core/styles';
import theme from 'theme';

import { baseStyles } from 'suspended-page/styles';

export default makeStyles({
  ...baseStyles,
  firstMessage: {
    marginTop: `${theme.spacing(1)}px !important`,
    marginBottom: `${theme.spacing(2)}px !important`,
  },
  secondMessage: {
    marginTop: `${theme.spacing(1)}px !important`,
    marginBottom: `${theme.spacing(4)}px !important`,
  },
});
