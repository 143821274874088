import React, { useEffect } from 'react';
import { string, node } from 'prop-types';

import { useSeoContext } from 'contexts/seo-context';
import { useNoticationContext } from 'contexts/notification-context';

const Page = ({
  children = null,
  title,
  description = 'Digital loyalty made easy for everyone',
}) => {
  const { setHelmet = () => {} } = useSeoContext();
  const { MemoNotification } = useNoticationContext();

  useEffect(() => {
    setHelmet({ title, description });
    // eslint-disable-next-line
  }, [title,  description]);

  return (
      <>
        {children}
        {MemoNotification}
      </>
  );
};

Page.propTypes = {
  children: node,
  title: string.isRequired,
  description: string,
};

export default Page;
