import React, { useState, useEffect } from 'react';
import { bool } from 'prop-types';
import root from 'window-or-global';
import { footerTheme } from 'theme';
import { navigate } from 'gatsby';
import { ThemeProvider } from '@material-ui/core/styles';
import {
  Container, BottomNavigation, BottomNavigationAction, Tooltip,
} from '@material-ui/core';
import {
  LANDING_PAGE, SUPPORT_HOME,
  SUPPORT_PROBLEM, SUPPORT_DOCS,
  HOME_LOGGED_IN_ROUTE,
} from 'constants/navigation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHome, faUserHeadset, faBug, faFileAlt,
} from '@fortawesome/pro-light-svg-icons';

import { isAuthenticated } from 'services/authentication-service';

import useStyles, { useToolTipClasses } from './styles';

const Footer = () => {
  const styles = useStyles();
  const toolTipClasses = useToolTipClasses();

  const [footerItem, setFooterItem] = useState('');

  useEffect(() => {
    const waitToLoad = async () => {
      const { location = { } } = root || {};
      const { pathname = '/' } = location;

      const setValue = (val) => {
        if (footerItem !== val) {
          setFooterItem(val);
        }
      };

      if (pathname.includes(LANDING_PAGE) || pathname.includes(HOME_LOGGED_IN_ROUTE)) {
        setValue('home');
      }

      if (pathname.includes(SUPPORT_HOME)) {
        setValue('support');
      }

      if (pathname.includes(SUPPORT_PROBLEM)) {
        setValue('contact');
      }

      if (pathname.includes(SUPPORT_DOCS)) {
        setValue('documents');
      }
    };

    waitToLoad();

    // eslint-disable-next-line
  }, []);

  const authenticated = isAuthenticated();

  const homeRoute = authenticated ? HOME_LOGGED_IN_ROUTE : LANDING_PAGE;

  return (
    <ThemeProvider theme={footerTheme}>
      <footer className={styles.darkFooter}>
      <Container className={styles.container}>
        <BottomNavigation value={footerItem}
          className={styles.bottomNavContainer}
          onChange={(event, newValue) => {
            if (newValue) {
              setFooterItem(newValue);
            } else {
              setFooterItem('');
            }
          }} >
            {footerItem === 'home' ? (
              <BottomNavigationAction
                label="Home" value="home"
                icon={<FontAwesomeIcon icon={faHome} size="lg" className={styles.icon}/>}
                onClick={() => navigate(homeRoute)}
                className={styles.bottomNav}
              />
            ) : (
              <Tooltip title="Home" aria-label="home" placement="top" classes={toolTipClasses} arrow>
                <BottomNavigationAction
                  label="Home" value="home"
                  icon={<FontAwesomeIcon icon={faHome} size="lg" className={styles.icon}/>}
                  onClick={() => navigate(homeRoute)}
                  className={styles.bottomNav}
                />
              </Tooltip>
            )}
            {footerItem === 'support' ? (
              <BottomNavigationAction
                label="Support" value="support"
                icon={<FontAwesomeIcon icon={faUserHeadset} size="lg" className={styles.icon}/>}
                onClick={() => navigate(SUPPORT_HOME)}
                className={styles.bottomNav}
              />
            ) : (
              <Tooltip title="Support" aria-label="support" placement="top" classes={toolTipClasses} arrow>
                <BottomNavigationAction
                  label="Support" value="support"
                  icon={<FontAwesomeIcon icon={faUserHeadset} size="lg" className={styles.icon}/>}
                  onClick={() => navigate(SUPPORT_HOME)}
                  className={styles.bottomNav}
                />
              </Tooltip>
            )}
            {footerItem === 'contact' ? (
              <BottomNavigationAction
                label="Get Support" value="contact"
                icon={<FontAwesomeIcon icon={faBug} size="lg" className={styles.icon}/>}
                onClick={() => navigate(SUPPORT_PROBLEM)}
                className={styles.bottomNav}
              />
            ) : (
              <Tooltip title="Get Support" aria-label="Get Support" placement="top" classes={toolTipClasses} arrow>
                <BottomNavigationAction
                  label="Get Support" value="contact"
                  icon={<FontAwesomeIcon icon={faBug} size="lg" className={styles.icon}/>}
                  onClick={() => navigate(SUPPORT_PROBLEM)}
                  className={styles.bottomNav}
                />
              </Tooltip>
            )}
            {footerItem === 'documents' ? (
              <BottomNavigationAction
                label="Documents" value="documents"
                icon={<FontAwesomeIcon icon={faFileAlt} size="lg" className={styles.icon}/>}
                onClick={() => navigate(SUPPORT_DOCS)}
                className={styles.bottomNav}
                />
            ) : (
              <Tooltip title="Documents" aria-label="documents" placement="top" classes={toolTipClasses} arrow>
                <BottomNavigationAction
                  label="Documents"
                  value="documents"
                  icon={<FontAwesomeIcon icon={faFileAlt} size="lg" className={styles.icon}/>}
                  onClick={() => navigate(SUPPORT_DOCS)}
                  className={styles.bottomNav}
                  />
              </Tooltip>
            )}
        </BottomNavigation>
      </Container>
      </footer>
    </ThemeProvider>
  );
};

Footer.propTypes = {
  sticky: bool,
};

export default Footer;
